import UrlParser from "../routes/url-parser";
import routes from "../routes/routes";

class App {
  constructor({ content }) {
    this._content = content;
    this._initialAppShell();
  }

  _initialAppShell() {}

  async renderPage() {
    const url = UrlParser.parseActiveUrlWithCombiner();
    const page = routes[url];
    if (!page) {
      console.error(`Halaman tidak ditemukan untuk URL: ${url}`);
      this._content.innerHTML = `
      <div class="content textPutih" tabindex="0">
        <div class="container d-flex flex-column justify-content-center align-items-center vh-100 text-center">
          <h1 class="textMerah text-center">404 - Halaman tidak ditemukan</h1>
          <img data-src="images/logo.png" alt="404" class="img-fluid lazyload">
        </div>
      </div>
      `;
      loader.style.animation = "fadeOut 1s ease forwards";
      loader.addEventListener("animationend", () => {
        loader.style.display = "none";
      });
      return;
    }
    this._content.innerHTML = await page.render();
    if (page.afterRender) {
      await page.afterRender();
    }
  }
}

export default App;