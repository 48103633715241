import CONFIG from "../../../globals/config";

const content1Page = (content1Source) => `
<div class="container">
  <h1 class="text-danger fw-bold text-center mt-3">Media Partner Kami</h1>
  <p class="text-center text-white">
    Kamu dapat melihat beberapa artikel kegiatan kami bersama komunitas yang berkolaborasi
  </p>
  <div class="row g-4 mt-4">
    <!-- Partner 1 -->
    ${content1Source
      .map(
        (source) => `
        <div class="col-md-4">
          <div class="card MediaPartner-card">
            <img 
            data-src="${source.pictureUrl}" 
            class="card-img-top lazyload" 
            alt="${source.name}" />
            <div class="card-body body-MediaPartner">
              <h5 class="card-title">${source.name}</h5>
              <a 
              href="#/media-partner/detail/${source.id}" 
              class="btn btn-light">Lihat disini</a>
            </div>
          </div>
        </div>
        `
      )
      .join("")}
  </div>
</div>
`;

export { content1Page };
