const ProfileDetail = (profile) => `
  <div class="container mt-5">
    <div class="row g-5">
      <!-- Bagian Gambar -->
      <div class="col-md-4 text-center">
        <img 
          data-src="${profile.pictureUrl}" 
          alt="${profile.name}" 
          class="lazyload img-fluid rounded-circle shadow"
          style="max-width: 200px; height: auto;"
        />
      </div>

      <!-- Bagian Detail -->
      <div class="col-md-8 ">
        <h1 class="text-danger fw-bold">${profile.name}</h1>
        <h4 class="text-light">${profile.position}</h4>
        <p><strong class="text-danger fw-bold">Email:</strong> <a href="mailto:${
          profile.email
        }" class="text-decoration-none text-light">${profile.email}</a></p>
        
        <div class="mt-4">
          <h5 class="text-danger fw-bold">Visi & Misi</h5>
          <p class="text-light">${profile.visiMisi}</p>
        </div>

        <div class="mt-4">
          <h5 class="text-danger fw-bold">Tentang</h5>
          <p class="text-light">${profile.about}</p>
        </div>

        <small class="text-light">Dibuat pada: ${new Date(
          profile.createdAt
        ).toLocaleDateString()}</small>
      </div>
    </div>
  </div>
`;

const ActivityDetail = (activity) => `
  <div class="container mt-5 text-light">
    <!-- Judul -->
    <div class="row">
      <div class="col-12 text-center">
        <h1 class="text-warning fw-bold">${activity.title}</h1>
      </div>
    </div>

    <!-- Carousel untuk Gambar -->
    <div id="activityCarousel" class="carousel slide mt-4" data-bs-ride="carousel">
      <div class="carousel-inner">
        ${activity.pictureUrl
          .map(
            (url, index) => `
          <div class="carousel-item ${index === 0 ? "active" : ""}">
            <img data-src="${url}" class="lazyload d-block w-100 img-fluid" style="height: 400px; object-fit: cover;" alt="Activity Image ${
              index + 1
            }" />
          </div>
        `
          )
          .join("")}
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#activityCarousel" data-bs-slide="prev">
        <span class="carousel-control-prev-icon bg-dark" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#activityCarousel" data-bs-slide="next">
        <span class="carousel-control-next-icon bg-dark" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <!-- Deskripsi -->
    <div class="row mt-4">
      <div class="col-12">
        <h5 class="fw-bold text-warning">Deskripsi</h5>
        <p class="text-info">${activity.description}</p>
      </div>
    </div>

    <!-- Detail Aktivitas -->
    <div class="row mt-5">
      <div class="col-md-6">
        <h5 class="fw-bold text-warning">Tanggal</h5>
        <p class="text-info">${new Date(activity.date).toLocaleDateString()}</p>
      </div>
      <div class="col-md-6">
        <h5 class="fw-bold text-warning">Waktu</h5>
        <p class="text-info">${activity.time}</p>
      </div>
    </div>

    <!-- Metadata -->
    <div class="row mt-4">
      <div class="col-md-6">
        <small class="text-danger">Dibuat pada: ${new Date(
          activity.createdAt
        ).toLocaleString()}</small>
      </div>
      <div class="col-md-6 text-end">
        <small class="text-danger">Diperbarui pada: ${new Date(
          activity.updatedAt
        ).toLocaleString()}</small>
      </div>
    </div>
  </div>
`;

const mediaPartnerDetail = (mediaPartner) => `
  <div class="container mt-5 text-light">
    <!-- Gambar -->
    <div class="row justify-content-center">
      <div class="col-md-8 text-center">
        <img 
          data-src="${mediaPartner.pictureUrl}" 
          class="img-fluid rounded lazyload" 
          alt="${mediaPartner.name}" 
          style="max-height: 400px; object-fit: cover; border: 3px solid #ffc107;"
        />
      </div>
    </div>

    <!-- Judul -->
    <div class="row mt-4">
      <div class="col-12 text-center">
        <h1 class="text-warning fw-bold">${mediaPartner.name}</h1>
        <h5 class="text-info">${mediaPartner.title}</h5>
      </div>
    </div>

    <!-- Deskripsi -->
    <div class="row mt-4">
      <div class="col-12">
        <h5 class="fw-bold text-warning">Deskripsi</h5>
        <p class="text-info">${mediaPartner.description}</p>
      </div>
    </div>
  </div>
`;

const eventDetail = (event, paymentData) => `
  <div class="container mt-5 text-light">
    <!-- Gambar Acara -->
    <div class="row justify-content-center">
      <div class="col-md-8 text-center">
        <img 
          data-src="${event.pictureUrl}" 
          class="img-fluid rounded lazyload" 
          alt="${event.title}" 
          style="max-height: 400px; object-fit: cover; border: 3px solid #ffc107;"
        />
      </div>
    </div>

    <!-- Judul Acara -->
    <div class="row mt-4">
      <div class="col-12 text-center">
        <h1 class="text-warning fw-bold">${event.title}</h1>
      </div>
    </div>

    <!-- Detail Acara -->
    <div class="row mt-4 align-items-center justify-content-center text-center">
      <div class="col-md-6">
        <h5 class="fw-bold text-warning">Tanggal</h5>
        <p class="text-info">${new Date(event.date).toLocaleDateString()}</p>
      </div>
      <div class="col-md-6">
        <h5 class="fw-bold text-warning">Waktu</h5>
        <p class="text-info">${event.startTime} - ${event.endTime}</p>
      </div>
      <div class="col-md-6 mt-3">
        <h5 class="fw-bold text-warning">Lokasi</h5>
        <p class="text-info">${event.venue}</p>
        <p class="text-info">${event.address}</p>
      </div>
      <div class="col-md-6 mt-3">
        <h5 class="fw-bold text-warning">Harga Tiket</h5>
        <p class="text-info">Rp${event.price.toLocaleString("id-ID")}</p>
        <h5 class="fw-bold text-warning mt-3">Tiket Tersedia</h5>
        <p class="text-info">${event.availableTickets} tiket</p>
      </div>
    </div>

    <!-- Button Checkout -->
    <div class="row mt-5">
      <div class="col-12 text-center">
        <button class="btn btn-warning btn-lg" id="buyTicketButton">
          Beli Tiket
        </button>
      </div>
    </div>
  </div>

  <!-- Modal Form -->
  <div class="modal" id="buyTicketModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content text-dark">
        <div class="modal-header">
          <h5 class="modal-title">Form Pembelian Tiket</h5>
          <button type="button" class="btn-close" id="closeModalButton"></button>
        </div>
        <div class="modal-body">
          <form id="ticketForm">
            <div class="mb-3">
              <label for="customerName" class="form-label">Nama Lengkap</label>
              <input type="text" class="form-control" id="customerName">
            </div>
            <div class="mb-3">
              <label for="email" class="form-label">Email</label>
              <input type="email" class="form-control" id="email">
            </div>
            <div class="mb-3">
              <label for="phoneNumber" class="form-label">Nomor Whatsapp</label>
              <div class="input-group">
                <!-- Dropdown untuk kode negara -->
                <select class="form-select" id="countryCode" style="max-width: 120px;">
                  <option value="+62" selected>+62 </option>
                  <option value="+1">+1 </option>
                  <option value="+44">+44 </option>
                  <option value="+91">+91 </option>
                  <option value="+81">+81 </option>
                  <!-- Tambahkan lebih banyak kode negara sesuai kebutuhan -->
                </select>
                <!-- Input untuk nomor telepon -->
                <input 
                  type="tel" 
                  class="form-control" 
                  id="phoneNumber" 
                  placeholder="Nomor Whatsapp"
                />
              </div>
            </div>
            <div class="mb-3">
              <label for="paymentMethod" class="form-label">Metode Pembayaran</label>
              <select class="form-select" id="paymentMethod">
              ${paymentData.map(
                (payment) => `
                  <option value="${payment.id}">${payment.bankName}</option>
                `
              )}
              </select>
            </div>
            <div class="mb-3">
              <label for="ticketQuantity" class="form-label">Jumlah Tiket</label>
              <div class="input-group">
                <!-- Tombol Kurang -->
                <button class="btn btn-outline-warning" type="button" id="decreaseQuantity">-</button>
                <!-- Input Jumlah Tiket -->
                <input 
                  type="number" 
                  class="form-control text-center" 
                  id="ticketQuantity" 
                  value="1" 
                  min="1" 
                  max="${event.availableTickets}" 
                />
                <!-- Tombol Tambah -->
                <button class="btn btn-outline-warning" type="button" id="increaseQuantity">+</button>
              </div>
              <small class="form-text text-muted">
                Maksimum ${event.availableTickets} tiket dapat dibeli.
              </small>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" id="closeModalFooterButton">Tutup</button>
          <button type="submit" class="btn btn-warning" form="ticketForm">Konfirmasi Pembelian</button>
        </div>
      </div>
    </div>
  </div>
`;


const orderDetail = (order) => `
  <div class="container my-5 card">
  <!-- Title -->
  <h1 class="text-center mb-4 mt-4">DETAIL PEMESANAN</h1>
  <div class="card mb-4 p-2" style="max-width: 100%; border-radius: 12px; box-shadow: 0px 4px 6px rgba(0,0,0,0.1);">
    <div class="card-body">
    ${
      order.status === "pending"
        ? `
      <h2 class="card-title mb-4 ">
        STATUS: <span class="text-warning">MENUNGGU PEMBAYARAN</span>
      </h2>
      <hr class="">
      ${
        order.paymentMethod.pictureUrl !== null
          ? `
          <div class="text-center">
            <img '
            data-src="${order.paymentMethod.pictureUrl}" '
            alt="Image ${order.paymentMethod.bankName}" 
            style="width: 400px; height: 400px; object-fit: contain; margin: 0 auto;"
            class="qr-code lazyload">
          </div>
          
          <table class="table table-borderless">
            <tbody>
              <tr>
                <td>Metode Pembayaran</td>
                <td class="text-end">${order.paymentMethod.bankName}</td>
              </tr>
              <tr>
                <td>Nama Pemilik</td>
                <td class="text-end">${order.paymentMethod.accountHolder}</td>
              </tr>
              <tr>
                <td>No Rekening</td>
                <td class="text-end">${order.paymentMethod.accountNumber}</td>
              </tr>
              <tr class="table-active fw-bold">
                <td>Total Pembayaran</td>
                <td class="text-end text-danger">${order.totalAmount}</td>
              </tr>
            </tbody>
          </table>
        `
          : `
          <table class="table table-borderless">
            <tbody>
              <tr>
                <td>Metode Pembayaran</td>
                <td class="text-end">${order.paymentMethod.bankName}</td>
              </tr>
              <tr>
                <td>Nama Pemilik</td>
                <td class="text-end">${order.paymentMethod.accountHolder}</td>
              </tr>
              <tr>
                <td>No Rekening</td>
                <td class="text-end">${order.paymentMethod.accountNumber}</td>
              </tr>
              <tr class="table-active fw-bold">
                <td>Total Pembayaran</td>
                <td class="text-end text-danger">${order.totalAmount}</td>
              </tr>
            </tbody>
          </table>
          `
      }
    </div>
    <div class="card-footer py-4">
      <h5 class="card-title text-danger mb-3">
        <i class="bi bi-exclamation-triangle-fill me-2"></i> NOTE
      </h5>
      <hr class="mb-4">
      <ul class="list-group list-group-flush">
        <li class="list-group-item d-flex align-items-start">
          <i class="bi bi-calendar-check-fill text-warning me-2"></i>
          <span>Pembayaran harus dilakukan sebelum acara dimulai.</span>
        </li>
        <li class="list-group-item d-flex align-items-start">
          <i class="bi bi-envelope-at-fill text-info me-2"></i>
          <span>Setelah pembayaran, unggah bukti transfer Anda melalui email ke <strong>admin@ikscrew.com</strong>. untuk verifikasi pembayaran.</span>
        </li>
        <li class="list-group-item d-flex align-items-start">
          <i class="bi bi-archive-fill text-primary me-2"></i>
          <span>Simpan bukti pembayaran hingga proses verifikasi selesai.</span>
        </li>
        
        <li class="list-group-item d-flex align-items-start">
          <i class="bi bi-headset text-secondary me-2"></i>
          <span>Hubungi Help Desk di <strong>helpdesk@ikscrew.com</strong> jika ada kendala.</span>
        </li>
      </ul>
    </div>
  </div>
        `
        : order.status === "cancelled"
        ? `<h2 class="card-title mb-4 ">
          STATUS: <span class="text-danger">PESANAN DIBATALKAN</span>
        </h2>
        <hr>
        <table class="table table-borderless">
            <tbody>
              <tr>
                <td>Metode Pembayaran</td>
                <td class="text-end">${order.paymentMethod.bankName}</td>
              </tr>
              <tr>
                <td>Nama Pemilik</td>
                <td class="text-end">${order.paymentMethod.accountHolder}</td>
              </tr>
              <tr>
                <td>No Rekening</td>
                <td class="text-end">${order.paymentMethod.accountNumber}</td>
              </tr>
              <tr class="table-active fw-bold">
                <td>Total Pembayaran</td>
                <td class="text-end text-danger">${order.totalAmount}</td>
              </tr>
            </tbody>
          </table>
        </div>
  </div>
        `
        : `
        <h5 class="card-title text-danger">
          Informasi Cutomer
        </h5>
        <hr>
        <table class="table table-borderless">
            <tbody>
              <tr>
                <td>Nama</td>
                <td class="text-end">${order.customerName}</td>
              </tr>
              <tr>
                <td>No Telepon</td>
                <td class="text-end">${order.phoneNumber}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td class="text-end">${order.email}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
        `
    }

  <div class="card mb-4" style="max-width: 100%; border-radius: 12px; box-shadow: 0px 4px 6px rgba(0,0,0,0.1);">
    <div class="card-body">
    <!-- Order Item -->
    ${order.events
      .map(
        (event) => `
      <a href="#/event/detail/${event.id}" class="text-dark">
        <div class="d-flex align-items-center">
          <!-- Product Image -->
          <img data-src="${event.pictureUrl}" 
          class="img-thumbnail me-4 lazyload" 
          alt="Image ${event.title}" 
          style="width: 120px; height: auto;">
          
          <!-- Product Details -->
          <div class="flex-grow-1">
            <h5 class="card-title">${event.title}</h5>
            <p class="mb-0">Rp ${event.price.toLocaleString("id-ID")}</p>
          </div>

          <!-- Price -->
          <div class="text-end">
            <h5 class="fw-bold me-4">${event.OrderEvent.quantity}</h5>
          </div>
        </div>
      </a>
      <hr>
      `
      )
      .join(``)}
      <table class="table table-borderless mt-3">
        <tbody>
          <tr>
            <td>Total Quantity</td>
            <td class="text-end">${order.quantity}</td>
          </tr>
          
          <tr class="table-active fw-bold">
            <td>Total Pesanan</td>
            <td class="text-end text-danger">${order.totalAmount}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="card mb-4" style="max-width: 100%; border-radius: 12px; box-shadow: 0px 4px 6px rgba(0,0,0,0.1);">
    <div class="card-body">
      <h5 class="card-title text-danger">
        Metode Pembayaran
      </h5>
      <hr>
      <table class="table table-borderless">
        <tbody>
          <tr>
            <td>Metode Pembayaran</td>
            <td class="text-end">${order.paymentMethod.bankName}</td>
          </tr>
          <tr>
            <td>Nama Pemilik</td>
            <td class="text-end">${order.paymentMethod.accountHolder}</td>
          </tr>
          <tr>
            <td>No Rekening</td>
            <td class="text-end">${order.paymentMethod.accountNumber}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

`;

export {
  ProfileDetail,
  ActivityDetail,
  mediaPartnerDetail,
  eventDetail,
  orderDetail,
};
