import CONFIG from "../../../globals/config";

const content1Page = (content1Source) => `
<div class="container my-5">
  <div class="row justify-content-center">
    <div class="text-center mb-4">
      <h1>Aktivitas Seru Bersama <span class="text-danger fw-bold">IKS Crew</span></h1>
      <p class="lead">
        Dengan antusiasme yang tinggi, 
        komunitas IKS telah berhasil menyelenggarakan berbagai 
        acara seru yang terus berinovasi, mempererat tali persaudaraan, 
        dan memberikan kesempatan bagi anggota untuk mengembangkan diri.
      </p>
    </div>
    ${content1Source
      .map(
        (source) => `
        <a href="#/aktivitas/detail/${
          source.id
        }" class="mb-4" data-aos="fade-up" data-aos-duration="1000">
          <div class="activity-card text-white border-0">
            <img
              data-src="${source.pictureUrl[0]}"
              class="lazyload card-img-top rounded"
              alt="${source.title}"
              data-aos="zoom-in" data-aos-duration="1500"
            />
            <div class="card-body">
              <div class="activity-item">
                <div class="Judul" data-aos="fade-right" data-aos-duration="1200">${
                  source.title
                }</div>
                <div class="description" data-aos="fade-left" data-aos-duration="1200">
                ${source.description || "Deskripsi tidak tersedia"}
                </div>
              </div>
            </div>
          </div>
        </a>`
      )
      .join("")}
  </div>
</div>
`;

export { content1Page };
