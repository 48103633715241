import landing from "../views/pages/home";
import Profile from "../views/pages/profile";
import Activity from "../views/pages/activity";
import MediaPartner from "../views/pages/media-partner";
import Event from "../views/pages/event";

import Regis from "../views/pages/regis";
import Login from "../views/pages/login";
import forgotPasswordPage from "../views/pages/forgotPassword";

// halaman detail
import {
  detailProfile,
  detailActivity,
  detailMediaPartner,
  detailEvent,
  detailOder,
} from "../views/pages/detail";

// dashboard
import Dashboard from "../views/pages/dashboard";

// admin
import {
  editlanding,
  editProfile,
  editAktivitas,
  editMediaPartner,
  editSupport,
  editEvent,
  editpayments,
  adminTransaksi,
  userTransaksi,
} from "../views/pages/AdminPages.js";

// halaman order
import { orderPage } from "../views/pages/orderPage";

const routes = {
  "/": landing, // default page
  "/profile": Profile,
  "/aktivitas": Activity,
  "/media-partner": MediaPartner,
  "/event": Event,

  "/register": Regis,
  "/login": Login,
  "/forgot-password": forgotPasswordPage,

  /*halaman detail*/
  "/profile/detail/:id": detailProfile,
  "/aktivitas/detail/:id": detailActivity,
  "/media-partner/detail/:id": detailMediaPartner,
  "/event/detail/:id": detailEvent,
  // "/order/detail/:id": detailOder,

  /* halaman Order */
  "/checkout": orderPage,
};
const user = JSON.parse(localStorage.getItem("user"));
if (user) {
  
  routes["/order/detail/:id"] = detailOder;


  if (user.role === "admin") {
    routes["/admin/dashboard"] = Dashboard;
    routes["/admin/editlanding"] = editlanding;
    routes["/admin/editprofile"] = editProfile;
    routes["/admin/editaktivitas"] = editAktivitas;
    routes["/admin/editmedia-partner"] = editMediaPartner;
    routes["/admin/editsupport"] = editSupport;
    routes["/admin/editevent"] = editEvent;
    routes["/admin/editpayments"] = editpayments;
    routes["/admin/transaksi"] = adminTransaksi;
  } else if (user.role === "user") {
    routes["/user/dashboard"] = Dashboard;
    routes["/user/transaksi"] = userTransaksi;
  } else {
    Swall.fire({
      icon: "error",
      title: "Oops...",
      text: "Anda harus login terlebih dahulu!",
    })
  }
  
}
export default routes;
